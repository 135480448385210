"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _SearchBox = _interopRequireDefault(require("./SearchBox"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchBar = ({
  variant = 'searchpage',
  className,
  active,
  hideSearchIcon,
  homeBarLabel
}) => __jsx(Container, {
  variant: variant,
  className: `${className ?? ''} searchbar`
}, __jsx(_SearchBox.default, {
  hideSearchIcon: hideSearchIcon,
  hideCalendarIcon: true,
  active: active,
  variant: variant,
  homeBarLabel: homeBarLabel
}));
const Container = _styledComponents.default.div.withConfig({
  displayName: "SearchBar__Container",
  componentId: "sc-2me5g3-0"
})(["display:flex;align-items:center;border-radius:calc(", " + ", "*0.5);position:relative;z-index:3;", " @media screen and (min-width:", "){padding-top:0;gap:2rem;", "}", ""], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  variant
}) => (variant === 'searchpage' || variant === 'homeBar') && (0, _styledComponents.css)(["padding-top:2.4rem;@media screen and (min-width:", "){padding-top:4rem;}"], ({
  theme
}) => theme.mediaQueries.xs), ({
  theme
}) => theme.mediaQueries.s, ({
  variant
}) => variant === 'hero' ? (0, _styledComponents.css)(["padding:0;"]) : (0, _styledComponents.css)(["padding:4rem 0;"]), ({
  variant
}) => variant === 'searchMap' && (0, _styledComponents.css)(["@media screen and (max-width:", "px){position:absolute;top:4rem;left:var(--wrapperPadding);margin:0;}@media screen and (max-width:", "px){top:2.4rem;right:var(--wrapperPadding);}@media screen and (min-width:", "){margin-top:4rem;}"], ({
  theme
}) => theme.mediaQueriesValues.m - 1, ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.mediaQueries.m));
var _default = exports.default = SearchBar;